export default {
  init() {
    $('#modal-image').on('show.bs.modal', function(e) {
      $('#modal-image .modal-body').empty();
      var $invoker = $(e.relatedTarget);
      $($invoker).find('figure').clone().appendTo('#modal-image .modal-body');
      $('#modal-image .modal-body figure').removeClass('mb-3').addClass('mb-0');
    });

    //while images are refreshing on the page put up loading white overlay to 
    //prevent people from selecting a second option too quickly which causes a bug
    $(document).on('facetwp-refresh', function() {
      $('.gallery-filter-loading-container').show();
    });

    //hide loading
    $(document).on('facetwp-loaded', function() {
      $('.gallery-filter-loading-container').hide();
    });
  },
};
