/**
 * Init
 */
export function confirmationPageInit() {


  if( global.ccUserInfo ) {
    const selectedCenter = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID];
    const googleMapsUrl = 'https://maps.google.com/?q=' + selectedCenter.address + ' ' + selectedCenter.address2 + ', ' + selectedCenter.city + ' , ' + selectedCenter.state + ' , ' + selectedCenter.zipcode;


    // adds google calendar dates
    $('.addeventatc .start').html(global.ccUserInfo.calendarStart);
    $('.addeventatc .end').html(global.ccUserInfo.calendarEnd);

    // adds calendar date/time
    $('.appointment-string-date').html(global.ccUserInfo.appointmentDate);
    $('.appointment-string-time').html(global.ccUserInfo.appointmentTime);

    // adds CC address
    //$('.appointment-personal-details').html('<p>' + global.ccUserInfo.firstName + ' ' + global.ccUserInfo.lastName + ' <br> ' + global.ccUserInfo.address + ' <br>' + global.ccUserInfo.city + ' , ' + global.ccUserInfo.state + ' ' + global.ccUserInfo.zipcode+'</p>');
    $('.appointment-center-address').html('<div>ClearChoice Dental Implant Center<br>' + selectedCenter.address + ' ' + selectedCenter.address2 + ' <br> ' + selectedCenter.city + ', ' + selectedCenter.state + ' ' + selectedCenter.zipcode + '</div>');


    // google maps change url
    $('.btn-directions').attr('href', googleMapsUrl);
    $('a').filter('[href^="http"], [href^="//"]').not('[href*="' + window.location.host + '"]').attr('rel', 'noopener noreferrer').attr('target', '_blank');
  }


  // @todo: get .mask() working on the phone number
  //$('#mobile-phone__number').mask('(000)000-0000', {placeholder: "(___)___-____"});

  confirmationPageValidatePhone();

}


/**
 * Validates the phone number field (under text)
 */
export function confirmationPageValidatePhone() {
  let _error = $('.mobile-phone__error');
  let _button = $('#mobile-phone__number');

  // toggle error msg. empty, spaces + numbers OK
  _button.on('input', function () {
    let val = $(this).val();
    let valid = /^(?=.*\d)[\d ]+$/.test(val);

    if( val === '' ) {
      _error.hide();
    } else {
      if( !valid )
        _error.show();
      else
        _error.hide();
    }

  });
}


/**
 * Save button reappears on any change -- click on any checkbox, change input.
 */
export function confirmationPageCheckboxClick() {
  let _preferences = $('.communication-preferences__container');

  // shows submit button, hides status when any checkboxes are changed/clicked
  _preferences.find('.preferences input').bind('change keyup', function () {
    _preferences.find('.mobile-phone__message--submit').show();
    _preferences.find('.mobile-phone__message--status').hide();
  });

}



/**
 * Click on Text checkbox, hide/show the Phone number container
 */
export function confirmationPageTextClick() {
  let _preferences = $('.communication-preferences__container');
  let _checkbox = _preferences.find('.preference-text input[type="checkbox"]');
  let _phoneContainer = _preferences.find('.preference-text').find('.mobile-phone__container');

  // toggle phone container on init
  // (makes sure to show container if text is already checked on page load)
  if (_checkbox.prop('checked'))
    _phoneContainer.show();
  else
    _phoneContainer.hide();

  // toggle phone container on text = checked
  _checkbox.click(function () {
    if ($(this).prop('checked'))
      _phoneContainer.show();
    else
      _phoneContainer.hide();
  });
}


/**
 * Click `Save Preferences` button on the Communication Preferences section/page, sends data to WP
 */

// deprecated/removed per CC-227

/*
export function confirmationPageSave() {
  let _preferences = getconfirmationPageContainer();
  let _submit = $(this);

  $('.mobile-phone__message--submit').click(function (e) {
    e.preventDefault();

    let checked;
    let leadData = {};
    let mobilePhoneNumber = _preferences.find('#mobile-phone__number').val();
    let _status = $('.mobile-phone__message--status');
    let _valid = /^(?=.*\d)[\d ]+$/.test(mobilePhoneNumber);
    let _numsOnly = mobilePhoneNumber.replace(/\D+/g, "");
    let _errorMessage = $('.mobile-phone__error');

    global.ccUserInfo.CommunicationPrefereneces = '';
    global.ccUserInfo.communicationPrefs = {};



    // populate leadData

    if (global.ccUserInfo.guid !== '')
      leadData.GUID = global.ccUserInfo.guid;


    leadData.action = 'cc_salesforce_save_communication_preferences';
    leadData.CommunicationPrefereneces = '';


    // email
    checked = _preferences.find('.preference-email input').prop('checked');
    if( checked ) {
      global.ccUserInfo.communicationPrefs.email = checked;
    }

    // phone
    // @todo: error check phone number
    checked = _preferences.find('.preference-phone input').prop('checked');
    if( checked ) {
      global.ccUserInfo.communicationPrefs.phone = checked;
    }

    // text
    checked = _preferences.find('.preference-text input').prop('checked');
    if( checked ) {
      global.ccUserInfo.communicationPrefs.text = checked;
      leadData.MobilePhone = mobilePhoneNumber;
      // If text checked and not valid then show error message & return before POST
      if ( !((_numsOnly.length > 9 && _numsOnly.length < 12) && (_valid)) ) {
        _errorMessage.show();
        return
      }
    }


    // if something was checked, generate semi-colon separated string for leadData
    if( global.ccUserInfo.communicationPrefs ) {
      let prefs = [];

      for( let pref in global.ccUserInfo.communicationPrefs )
        prefs.push(pref);

      // SF requires us to combine all the preferences and join them by ;
      leadData.CommunicationPrefereneces = prefs.join(';');
    }

    $.ajax({
      url: ajaxurl,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      cache: false,
      data: leadData,
      success: function (msg) {
        if( msg.success ) {
          _status.html('Thanks for letting us know how we can best communicate with you about your upcoming appointment.');
          _status.show();
          _submit.hide();
        } else {
          _status.html(msg.message);
          _errorMessage.show();
          // _status.show() was displaying blue error message they didn't want on the screen
          //  we might want to show this to debug if we find any future errors with this step
          //
          //_status.show();
          _submit.show();
        }
      },
      error: function() {
        _status.html('Error: Could not connect');
        _status.show();
        _submit.show();
      },
    });
  });

}
*/

/**
 * Get the container
 */
export function getconfirmationPageContainer() {
  return $('.communication-preferences__container');
}
