import { lookupCenter } from "./lookupCenter";
import {showWaitingIcon,hideWaitingIcon} from '../form/w1_helper_functions';
import * as ccForms from '../form/formFunctions';
import {is_w1, is_w2} from '../global/functions';
import {initMenu,getSuggestions} from '../form/addressVerification';
import Cookie from 'js-cookie';
import {displayAvailableAppointments} from '../form/displayAvailableAppointments';
import { saveLeadInfo, retrieveAppointments, saveUpdateLeadInfoAndRetrieveInfo} from '../form/initiateSFInfo';
import {getTimeZoneOffsets, getActualTime, printActualTime} from '../form/time';
import moment from 'moment';
import {submitForm} from '../form/submitForm';
import {w1_step3_2month,initiateStep3TwoMonthCal} from '../form/w1_step3_2month';
import {w1_step3} from '../form/w1_step3';

let is__w1 = is_w1();
let is__w2 = is_w2();
let zip;
let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

function getParam(p){
  var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function showCenterSelectionStep(){
  let is__w1 = is_w1();
  $('.page-few-questions .gform_page_footer').html(global.locateCenterForm);
  global.dataLayer.push({ 'event': 'Form-Step-1.5' });
  // tweak the locate center form to hide certain things
  if( is__w1 ) {
    // 100% width the `select a center` h2
    $('.page-few-questions .form-locate-center .col-sm-8').attr('class', 'col-12 form-locate-center');
    $('.page-few-questions .form-locate-center .col-sm-4').attr('class', 'd-none');
    // 100% width the center results
    $('.page-few-questions .form-locate-center-results .col-sm-7').attr('class', 'col-12 nearest-location-container');
    // hide the googlemaps
    $('.page-few-questions .form-locate-center-results .col-sm-5').addClass('d-none');
    // hide the zipcode stuff
    $('.page-few-questions .form-locate-center-lookup').addClass('d-none');
  }

  // mobile needs a bit more room on scroll
  if ($(window).width() < 768) {
    $('html, body').animate({
      scrollTop: $('.page-few-questions .form-group.bg-light').offset().top + 250,
    }, 250);
  } else {
    $('html, body').animate({
      scrollTop: $('.page-few-questions .form-group.bg-light').offset().top,
    }, 250);
  }

  if (!global.ccUserInfo.centerID) {
    $('.gform_next_button').hide();
  }


  let formID = $('input[name="gform_submit"]').val();
  $('#gform_target_page_number_'+formID).val(2);
  $('.gform_next_button').attr('onclick', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');
  $('.gform_next_button').attr('onkeypress', 'jQuery("#gform_target_page_number_'+formID+'").val("2");  jQuery("#gform_'+formID+'").trigger("submit",[true]);');

  $('#form-locate-center-address').keydown(function(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      console.log('test1');
      if(!global.switchedSteps){
        lookupCenter();
      }
      return false;
    }
  });

  if (global.ccUserInfo.zipcode) {
    $('#form-locate-center-address').val(global.ccUserInfo.zipcode);
  }
  if($('#form-locate-center-address').val() != undefined && $('#form-locate-center-address').val() != ''){
    if(((getParam('Center__c') != "" || getParam('Center__c') != null) && (getParam('trans_id') == 'coloxfer') == true)){
      $('.form-locate-center').addClass('d-none');
      $('.nearest-location-container').addClass('d-none');
      showWaitingIcon('Please Wait');
    }else{
      showWaitingIcon('Retrieving your nearest ClearChoice Centers');
    }
    lookupCenter();
  }

  if (!$('.validation_error').length) {
    // $('.page-gather-info .gform_page_fields').addClass('d-none');
  } else {
    $('.form-locate-center-lookup .btn').trigger('click');
  }

  $('a[href="#form-locate-center-form"]').click(function(e) {
    e.preventDefault();
    $('.gform_next_button').hide();
    $('.form-locate-center-results').addClass('d-none');
    $('.form-locate-center-results .list-group').empty();
    $('.form-locate-center-lookup').removeClass('d-none');
    $('.form-locate-center > .row .text-muted').removeClass('d-none');
    $('.form-locate-center-lookup input[type="text"]').focus();
    $('.page-gather-info .gform_page_fields').addClass('d-none');
  });
}

export function autofillPageGatherInfo(){
  if (global.ccUserInfo.firstName) {
    $('.field-firstName input').val(global.ccUserInfo.firstName);
  }

  if (global.ccUserInfo.lastName) {
    $('.field-lastName input').val(global.ccUserInfo.lastName);
  }

  if (global.ccUserInfo.phone) {
    $('.field-phone input').val(global.ccUserInfo.phone);
  }

  if (global.ccUserInfo.email) {
    $('.field-email input').val(global.ccUserInfo.email);
  }

  if (global.ccUserInfo.address) {
    $('.field-address input').val(global.ccUserInfo.address);
  }

  if (global.ccUserInfo.zipcode) {
    $('.field-zipcode input').val(global.ccUserInfo.zipcode);
  }

  if (global.ccUserInfo.city) {
    $('.field-city input').val(global.ccUserInfo.city);
  }

  if (global.ccUserInfo.state) {
    $('.field-state select').val(global.ccUserInfo.state);
  }

  if (global.ccUserInfo.birthDate) {
    $( '.field-birth-date-entry input' ).val(global.ccUserInfo.birthDate);
  }

  if(ccForms.checkSectionForComplete('page-gather-info')){
    $("#gform_next_button_34_11").removeClass("disabledButton");
  }
}

export function initializePageGatherInfo(){
  if(window.location.pathname.includes('colocation-schedule')){
    $('#webform-component-step-find-center--fieldset-header > h4').text("Please provide the patient information below.");
    $( "#gf_step_34_1 > span.gf_step_label" ).text("PATIENT INFORMATION");
  }

  hideWaitingIcon();

  if($('.field-firstName input').val() == ""){
    // add field blur event to dataLayer
      $(":input").blur(function(e) {
        var self = $(this);
        var label = $('label[for="' + self[0].id + '"]');
        var InputTarget =  $(e.relatedTarget).attr("id");
        if(InputTarget != undefined){
          if(InputTarget.includes('input')){
            if(label.length >= 0) {
              var parent = $(this).parent().parent().children(':first-child');
              var target = parent[0].innerText;
              if(target != "" && target != undefined && target != " " && target.length < 20){
                global.dataLayer.push({"event":"fullform_fields",
                  label_type: target,
                });
              }
            }
          }
        }
      });
    }
}

export function saveLeadCall(){
  $.when(global.retrieveLeadInfoLock).then(function(success){
    if(success && ccForms.checkChangesToPreformData()){
      saveUpdateLeadInfoAndRetrieveInfo();
    }else{
      saveLeadInfo();
    }
  });
}

export function fewQuestionsStepAddLabels(){
  $(".page-few-questions .gfield_description").remove();
  $(".page-few-questions .gfield_label").eq(0).append(' <div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select all that apply</div>');
  $(".page-few-questions .gfield_label").eq(1).append(' <div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select all that apply</div>');
  $(".page-few-questions .gfield_label").eq(3).append(' <div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select up to 3 options</div>');
  $("#field_34_37").addClass("accessibility_outline");
}

export function fewQuestionsStepAnimateToTop(){
  if ($(window).width() < 768) {
    $('html, body').animate({
      scrollTop: $('.page-few-questions').offset().top-30 ,
    }, 250);
  } else {
    $('html, body').animate({
      scrollTop: $('.page-few-questions').offset().top -100,
    }, 250);
  }
}

export function formAutocompleteSetUp(){
      // Consultation form
      if ($('form.schedule-consultation-form').length & global.formHasRun == false) {
        $('form.schedule-consultation-form').find('input[type=text]').addClass('inspectletIgnore');

        // change zipcode input type to 'tel' for keypad on mobile
        $('.field-zipcode input').prop('type', 'tel');


        // when using the zipcode field
        $( '.container' ).on( 'keyup change', '.field-zipcode input' , function() {
          var $zipfield = $( this );
          var zipVal = $zipfield.val();

          if ( zipVal.length === 5 && $.isNumeric( zipVal ) ) {
            $.ajax({
              type: 'POST',
              url: ajaxurl,
              dataType: 'json',
              contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
              data: {'zip':zipVal, 'action':'cc_salesforce_retrieve_ziptastic'},
              success: function(data) {
                if($.trim(data) == ''){
                  if( is__w1){
                    $zipfield.addClass('error zipcode-error');
                  }
                  else{
                    $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
                  }
                }else{
                   //Auto-fill the city/state from zipcode
                   var $city = $('.field-city input');
                   var $state = $('.field-state select');

                   $city.val(data.city);
                   $city.click()

                   $state.val(data.state_short);
                   $state.click();
                   if( is__w1){
                     $zipfield.removeClass('error zipcode-error');
                   }
                   else{
                     $zipfield.parents('.gfield').removeClass('gfield_error zipcode-error');
                   }
                }
              },

              error : function() {
                if( is__w1)
                  $zipfield.addClass('error zipcode-error');
                else
                  $zipfield.parents('.gfield').addClass('gfield_error zipcode-error');
              },

            });
          }


        } );

        initMenu();
        $(".field-address input").keyup(function (event) {
        var menu = $(".us-autocomplete-pro-menu");
        //if($("#data-street")[0].innerText) clearAddressData();
          var textInput = $(".field-address input").val();
          if (textInput.length > 0) {
            menu.show();
            getSuggestions(textInput);
          } else {
            menu.hide();
          }
        });
      }
}


