import {bonelossLP} from '../global/w1-bone-loss';

export default {
  init() {
    bonelossLP.init();
  },

  finalize() {
    // JavaScript to be fired after the init JS
  },
};
