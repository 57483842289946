export default {
    init() {

        var testElem = document.getElementsByClassName('right-edge')[0];

        if(testElem) {

            var xControl = document.getElementsByClassName('right-edge-control')[0].getBoundingClientRect().left;
            testElem.addEventListener("scroll", function(){
                var xTest = testElem.firstElementChild.getBoundingClientRect().left;

                if(xTest < xControl - 20){
                    testElem.classList.remove('shaded');
                }else{testElem.classList.add('shaded');}
            });
        }

    },
};
