export function responsiveTableStack(el) {
  let tables = document.querySelectorAll(el);
  let ii;


  // @todo: figure out why anonymous function doesnt work here
  //tables.forEach(table => {

  for (ii = 0; ii < tables.length; ii++) {
    let table = tables[ii];
    let i;
    let thArray = [];

    // if this is meant to stack w/ headers.
    let useHeader = table.classList.contains('table-stack-header');
    let ths = table.querySelectorAll("thead th");


    // build array of header names from <th>s
    for (i = 0; i < ths.length; i++)
      thArray.push(ths[i].innerHTML);

    if( useHeader )
      thArray.shift();

    // creates styles
    var styleEl = document.createElement("style"),
      styleSheet;
    document.head.appendChild(styleEl);

    styleSheet = styleEl.sheet;



    for (i = 0; i < thArray.length; i++) {
      // figure out how far to offset the td
      let offset = 1;
      if( useHeader )
        offset = 2;


      styleSheet.insertRule(
        "." +
        'table-stack-vertical' + // @todo: replace w/ id or class name
        " td:nth-child(" +
        (i + offset) +
        ')::before {content:"' +
        thArray[i] +
        ': ";}',
        styleSheet.cssRules.length
      );
    }



  }



}
