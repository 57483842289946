import Cookie from 'js-cookie';
export let helpers = {
	base64_encode(data) {
        // http://kevin.vanzonneveld.net
        // +   original by: Tyler Akins (http://rumkin.com)
        // +   improved by: Bayron Guevara
        // +   improved by: Thunder.m
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   bugfixed by: Pellentesque Malesuada
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Rafał Kukawski (http://kukawski.pl)
        // *     example 1: helpers.base64_encode('Kevin van Zonneveld');
        // *     returns 1: 'S2V2aW4gdmFuIFpvbm5ldmVsZA=='
        // mozilla has this native
        // - but breaks in 2.0.0.12!
        //if (typeof this.window['btoa'] == 'function') {
        //    return btoa(data);
        //}
        const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let o1;
        let o2;
        let o3;
        let h1;
        let h2;
        let h3;
        let h4;
        let bits;
        let i = 0;
        let ac = 0;
        let enc = "";
        const tmp_arr = [];

        if (!data) {
			return data;
		}

        do { // pack three octets into four hexets
			o1 = data.charCodeAt(i++);
			o2 = data.charCodeAt(i++);
			o3 = data.charCodeAt(i++);

			bits = o1 << 16 | o2 << 8 | o3;

			h1 = bits >> 18 & 0x3f;
			h2 = bits >> 12 & 0x3f;
			h3 = bits >> 6 & 0x3f;
			h4 = bits & 0x3f;

			// use hexets to index into b64, and append result to encoded string
			tmp_arr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4);
		} while (i < data.length);

        enc = tmp_arr.join('');

        const r = data.length % 3;

        return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3);
    },

	base64_decode(data) {
        // http://kevin.vanzonneveld.net
        // +   original by: Tyler Akins (http://rumkin.com)
        // +   improved by: Thunder.m
        // +      input by: Aman Gupta
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   bugfixed by: Onno Marsman
        // +   bugfixed by: Pellentesque Malesuada
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // *     example 1: helpers.base64_decode('S2V2aW4gdmFuIFpvbm5ldmVsZA==');
        // *     returns 1: 'Kevin van Zonneveld'
        // mozilla has this native
        // - but breaks in 2.0.0.12!
        //if (typeof this.window['atob'] == 'function') {
        //    return atob(data);
        //}
        const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let o1;
        let o2;
        let o3;
        let h1;
        let h2;
        let h3;
        let h4;
        let bits;
        let i = 0;
        let ac = 0;
        let dec = "";
        const tmp_arr = [];

        if (!data) {
			return data;
		}

        data += '';

        do { // unpack four hexets into three octets using index points in b64
			h1 = b64.indexOf(data.charAt(i++));
			h2 = b64.indexOf(data.charAt(i++));
			h3 = b64.indexOf(data.charAt(i++));
			h4 = b64.indexOf(data.charAt(i++));

			bits = h1 << 18 | h2 << 12 | h3 << 6 | h4;

			o1 = bits >> 16 & 0xff;
			o2 = bits >> 8 & 0xff;
			o3 = bits & 0xff;

			if (h3 == 64) {
				tmp_arr[ac++] = String.fromCharCode(o1);
			} else if (h4 == 64) {
				tmp_arr[ac++] = String.fromCharCode(o1, o2);
			} else {
				tmp_arr[ac++] = String.fromCharCode(o1, o2, o3);
			}
		} while (i < data.length);

        dec = tmp_arr.join('');

        return dec;
    },

	serialize(mixed_value) {
        // http://kevin.vanzonneveld.net
        // +   original by: Arpad Ray (mailto:arpad@php.net)
        // +   improved by: Dino
        // +   bugfixed by: Andrej Pavlovic
        // +   bugfixed by: Garagoth
        // +      input by: DtTvB (http://dt.in.th/2008-09-16.string-length-in-bytes.html)
        // +   bugfixed by: Russell Walker (http://www.nbill.co.uk/)
        // +   bugfixed by: Jamie Beck (http://www.terabit.ca/)
        // +      input by: Martin (http://www.erlenwiese.de/)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net/)
        // +   improved by: Le Torbi (http://www.letorbi.de/)
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net/)
        // +   bugfixed by: Ben (http://benblume.co.uk/)
        // %          note: We feel the main purpose of this function should be to ease the transport of data between php & js
        // %          note: Aiming for PHP-compatibility, we have to translate objects to arrays
        // *     example 1: helpers.serialize(['Kevin', 'van', 'Zonneveld']);
        // *     returns 1: 'a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}'
        // *     example 2: helpers.serialize({firstName: 'Kevin', midName: 'van', surName: 'Zonneveld'});
        // *     returns 2: 'a:3:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";s:7:"surName";s:9:"Zonneveld";}'
        let val;

        let key;
        let okey;
        let ktype = '';
        let vals = '';
        let count = 0;

        const _utf8Size = str => {
            let size = 0;
            let i = 0;
            const l = str.length;
            let code = '';
            for (i = 0; i < l; i++) {
				code = str.charCodeAt(i);
				if (code < 0x0080) {
					size += 1;
				}
				else if (code < 0x0800) {
					size += 2;
				}
				else {
					size += 3;
				}
			}
            return size;
        };

        const _getType = inp => {
            let match;
            let key;
            let cons;
            let types;
            let type = typeof inp;

            if (type === 'object' && !inp) {
				return 'null';
			}
            if (type === 'object') {
				if (!inp.constructor) {
					return 'object';
				}
				cons = inp.constructor.toString();
				match = cons.match(/(\w+)\(/);
				if (match) {
					cons = match[1].toLowerCase();
				}
				types = ['boolean', 'number', 'string', 'array'];
				for (key in types) {
					if (cons == types[key]) {
						type = types[key];
						break;
					}
				}
			}
            return type;
        };

        const type = _getType(mixed_value);

        switch (type) {
			case 'function':
				val = '';
				break;
			case 'boolean':
				val = `b:${mixed_value ? '1' : '0'}`;
				break;
			case 'number':
				val = `${Math.round(mixed_value) == mixed_value ? 'i' : 'd'}:${mixed_value}`;
				break;
			case 'string':
				val = `s:${_utf8Size(mixed_value)}:"${mixed_value}"`;
				break;
			case 'array': case 'object':
				val = 'a';
				/*
				if (type === 'object') {
					var objname = mixed_value.constructor.toString().match(/(\w+)\(\)/);
					if (objname == undefined) {
						return;
					}
					objname[1] = this.serialize(objname[1]);
					val = 'O' + objname[1].substring(1, objname[1].length - 1);
				}
				*/

				for (key in mixed_value) {
					if (mixed_value.hasOwnProperty(key)) {
						ktype = _getType(mixed_value[key]);
						if (ktype === 'function') {
							continue;
						}

						okey = (key.match(/^[0-9]+$/) ? parseInt(key, 10) : key);
						vals += this.serialize(okey) + this.serialize(mixed_value[key]);
						count++;
					}
				}
				val += `:${count}:{${vals}}`;
				break;
			case 'undefined':
				// if the JS object has a property which contains a null value, the string cannot be unserialized by PHP
				val = 'N';
				break;
			default:
				// if the JS object has a property which contains a null value, the string cannot be unserialized by PHP
				val = 'N';
				break;
		}
        if (type !== 'object' && type !== 'array') {
			val += ';';
		}
        return val;
    },

	unserialize(data) {
        // http://kevin.vanzonneveld.net
        // +     original by: Arpad Ray (mailto:arpad@php.net)
        // +     improved by: Pedro Tainha (http://www.pedrotainha.com)
        // +     bugfixed by: dptr1988
        // +      revised by: d3x
        // +     improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +        input by: Brett Zamir (http://brett-zamir.me)
        // +     improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +     improved by: Chris
        // +     improved by: James
        // +        input by: Martin (http://www.erlenwiese.de/)
        // +     bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +     improved by: Le Torbi
        // +     input by: kilops
        // +     bugfixed by: Brett Zamir (http://brett-zamir.me)
        // +      input by: Jaroslaw Czarniak
        // %            note: We feel the main purpose of this function should be to ease the transport of data between php & js
        // %            note: Aiming for PHP-compatibility, we have to translate objects to arrays
        // *       example 1: helpers.unserialize('a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}');
        // *       returns 1: ['Kevin', 'van', 'Zonneveld']
        // *       example 2: helpers.unserialize('a:3:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";s:7:"surName";s:9:"Zonneveld";}');
        // *       returns 2: {firstName: 'Kevin', midName: 'van', surName: 'Zonneveld'}
        // const that = this;

        const utf8Overhead = chr => {
			// http://phpjs.org/functions/unserialize:571#comment_95906
			const code = chr.charCodeAt(0);
			if (code < 0x0080) {
				return 0;
			}
			if (code < 0x0800) {
				return 1;
			}
			return 2;
		};

    //     const error = (type, msg, filename, line) => {
		// 	throw new that.window[type](msg, filename, line);
		// };

        const read_until = (data, offset, stopchr) => {
            let i = 2;
            const buf = [];
            let chr = data.slice(offset, offset + 1);

            while (chr != stopchr) {
				if ((i + offset) > data.length) {
					// error('Error', 'Invalid');
				}
				buf.push(chr);
				chr = data.slice(offset + (i - 1), offset + i);
				i += 1;
			}
            return [buf.length, buf.join('')];
        };

        const read_chrs = (data, offset, length) => {
			let i, chr, buf;

			buf = [];
			for (i = 0; i < length; i++) {
				chr = data.slice(offset + (i - 1), offset + i);
				buf.push(chr);
				length -= utf8Overhead(chr);
			}
			return [buf.length, buf.join('')];
		};

        const _unserialize = (data, offset) => {
			let dtype, dataoffset, keyandchrs, keys, readdata, readData, ccount, stringlength, i, key, kprops, kchrs, vprops, vchrs, value, chrs = 0, typeconvert = x => x;

			if (!offset) {
				offset = 0;
			}
			dtype = (data.slice(offset, offset + 1)).toLowerCase();

			dataoffset = offset + 2;

			switch (dtype) {
				case 'i':
					typeconvert = x => parseInt(x, 10);
					readData = read_until(data, dataoffset, ';');
					chrs = readData[0];
					readdata = readData[1];
					dataoffset += chrs + 1;
					break;
				case 'b':
					typeconvert = x => parseInt(x, 10) !== 0;
					readData = read_until(data, dataoffset, ';');
					chrs = readData[0];
					readdata = readData[1];
					dataoffset += chrs + 1;
					break;
				case 'd':
					typeconvert = x => parseFloat(x);
					readData = read_until(data, dataoffset, ';');
					chrs = readData[0];
					readdata = readData[1];
					dataoffset += chrs + 1;
					break;
				case 'n':
					readdata = null;
					break;
				case 's':
					ccount = read_until(data, dataoffset, ':');
					chrs = ccount[0];
					stringlength = ccount[1];
					dataoffset += chrs + 2;

					readData = read_chrs(data, dataoffset + 1, parseInt(stringlength, 10));
					chrs = readData[0];
					readdata = readData[1];
					dataoffset += chrs + 2;
					// if (chrs != parseInt(stringlength, 10) && chrs != readdata.length) {
					// 	error('SyntaxError', 'String length mismatch');
					// }
					break;
				case 'a':
					readdata = {};

					keyandchrs = read_until(data, dataoffset, ':');
					chrs = keyandchrs[0];
					keys = keyandchrs[1];
					dataoffset += chrs + 2;

					for (i = 0; i < parseInt(keys, 10); i++) {
						kprops = _unserialize(data, dataoffset);
						kchrs = kprops[1];
						key = kprops[2];
						dataoffset += kchrs;

						vprops = _unserialize(data, dataoffset);
						vchrs = vprops[1];
						value = vprops[2];
						dataoffset += vchrs;

						readdata[key] = value;
					}

					dataoffset += 1;
					break;
				default:
					// error('SyntaxError', `Unknown / Unhandled data type(s): ${dtype}`);
					break;
			}
			return [dtype, dataoffset - offset, typeconvert(readdata)];
		};

        return _unserialize((`${data}`), 0)[2];
    },

	/**
	 * Formate a string based on a pattern
	 *
	 * @param string string
	 * @param string pattern
	 * @param string delimiter Default '#'
	 * @return string
	 */
	string_format(string, pattern, delimiter) {
		try {
			if ( typeof( delimiter ) == 'undefined' ) {
				delimiter = '#';
			}
			let theReturn = '';
			const l = pattern.length;
			let z = 0;

			// replaces all non numbers
      string = string.replace(/\D/g, '');


			for ( let i = 0; i < l; i++ ) {
				if ( pattern[i] == delimiter ) {
					theReturn += string[z++];
				} else if ( pattern[ i ] == '\\' ) {
					theReturn += pattern[ ++i ];
				} else {
					theReturn += pattern[ i ];
				}
			}
			return theReturn;
		} catch( err ) {
			return '';
		}
	},

  /**
   * Format a phone number by pre-determained patterns
   *
   * @param string number
   * @param string pattern Default null
   * @param string loc Default 'US'
   * @return string
   */
  format_phonenumber: function( number, pattern, loc ) {

    if ( typeof( pattern ) == 'undefined' ) {
      pattern = null;
    }
    if ( typeof( loc ) == 'undefined' ) {
      loc = 'US';
    }
    try {
      let run = false;
      if ( loc == 'US' ) {
        let thenumber = number.replace( '/[^0-9]/', '' );
        if ( pattern !== null ) {
          run = true;
        } else if ( thenumber.length == 11 ) {
          pattern = '# (###) ###-####';
          run = true;
        } else if ( thenumber.length == 10 ) {
          pattern = '(###) ###-####';
          run = true;
        } else if ( thenumber.length == 7 ) {
          pattern = '###-####';
          run = true;
        }
        if ( run === true ) {
          return helpers.string_format( thenumber, pattern );
        }
      }
      return number;
    } catch( err ) {
      return '';
    }
  },

	utf8_encode(argString) {
        // http://kevin.vanzonneveld.net
        // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: sowberry
        // +    tweaked by: Jack
        // +   bugfixed by: Onno Marsman
        // +   improved by: Yves Sucaet
        // +   bugfixed by: Onno Marsman
        // +   bugfixed by: Ulrich
        // +   bugfixed by: Rafal Kukawski
        // +   improved by: kirilloid
        // +   bugfixed by: kirilloid
        // *     example 1: helpers.utf8_encode('Kevin van Zonneveld');
        // *     returns 1: 'Kevin van Zonneveld'

        if (argString === null || typeof argString === "undefined") {
			return "";
		}

        const string = (`${argString}`); // .replace(/\r\n/g, "\n").replace(/\r/g, "\n");
        let utftext = '';
        let start;
        let end;
        let stringl = 0;

        start = end = 0;
        stringl = string.length;
        for (let n = 0; n < stringl; n++) {
			let c1 = string.charCodeAt(n);
			let enc = null;

			if (c1 < 128) {
				end++;
			} else if (c1 > 127 && c1 < 2048) {
				enc = String.fromCharCode(
					(c1 >> 6)        | 192,
					( c1        & 63) | 128
					);
			} else if (c1 & 0xF800 != 0xD800) {
				enc = String.fromCharCode(
					(c1 >> 12)       | 224,
					((c1 >> 6)  & 63) | 128,
					( c1        & 63) | 128
					);
			} else { // surrogate pairs
				if (c1 & 0xFC00 != 0xD800) {
					// throw new RangeError(`Unmatched trail surrogate at ${n}`);
				}
				const c2 = string.charCodeAt(++n);
				if (c2 & 0xFC00 != 0xDC00) {
					// throw new RangeError(`Unmatched lead surrogate at ${n-1}`);
				}
				c1 = ((c1 & 0x3FF) << 10) + (c2 & 0x3FF) + 0x10000;
				enc = String.fromCharCode(
					(c1 >> 18)       | 240,
					((c1 >> 12) & 63) | 128,
					((c1 >> 6)  & 63) | 128,
					( c1        & 63) | 128
					);
			}
			if (enc !== null) {
				if (end > start) {
					utftext += string.slice(start, end);
				}
				utftext += enc;
				start = end = n + 1;
			}
		}

        if (end > start) {
			utftext += string.slice(start, stringl);
		}

        return utftext;
    },

	utf8_decode(str_data) {
        // http://kevin.vanzonneveld.net
        // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
        // +      input by: Aman Gupta
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Norman "zEh" Fuchs
        // +   bugfixed by: hitwork
        // +   bugfixed by: Onno Marsman
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   bugfixed by: kirilloid
        // *     example 1: helpers.utf8_decode('Kevin van Zonneveld');
        // *     returns 1: 'Kevin van Zonneveld'

        const tmp_arr = [];

        let i = 0;
        let ac = 0;
        let c1 = 0;
        let c2 = 0;
        let c3 = 0;
        let c4 = 0;

        str_data += '';

        while (i < str_data.length) {
			c1 = str_data.charCodeAt(i);
			if (c1 <= 191) {
				tmp_arr[ac++] = String.fromCharCode(c1);
				i++;
			} else if (c1 <= 223) {
				c2 = str_data.charCodeAt(i + 1);
				tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
				i += 2;
			} else if (c1 <= 239) {
				// http://en.wikipedia.org/wiki/UTF-8#Codepage_layout
				c2 = str_data.charCodeAt(i + 1);
				c3 = str_data.charCodeAt(i + 2);
				tmp_arr[ac++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
				i += 3;
			} else {
				c2 = str_data.charCodeAt(i + 1);
				c3 = str_data.charCodeAt(i + 2);
				c4 = str_data.charCodeAt(i + 3);
				c1 = ((c1 & 7) << 18) | ((c2 & 63) << 12) | ((c3 & 63) << 6) | (c4 & 63);
				c1 -= 0x10000;
				tmp_arr[ac++] = String.fromCharCode(0xD800 | ((c1>>10) & 0x3FF));
				tmp_arr[ac++] = String.fromCharCode(0xDC00 | (c1 & 0x3FF));
				i += 4;
			}
		}

        return tmp_arr.join('');
    },

	md5(str) {
        // http://kevin.vanzonneveld.net
        // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
        // + namespaced by: Michael White (http://getsprink.com)
        // +    tweaked by: Jack
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // -    depends on: helpers.utf8_encode
        // *     example 1: md5('Kevin van Zonneveld');
        // *     returns 1: '6e658d4bfcb59cc13f96c14450ac40b9'
        let xl;

        const rotateLeft = (lValue, iShiftBits) => (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));

        const addUnsigned = (lX, lY) => {
            let lX4;
            let lY4;
            let lX8;
            let lY8;
            let lResult;
            lX8 = (lX & 0x80000000);
            lY8 = (lY & 0x80000000);
            lX4 = (lX & 0x40000000);
            lY4 = (lY & 0x40000000);
            lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
            if (lX4 & lY4) {
				return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
			}
            if (lX4 | lY4) {
				if (lResult & 0x40000000) {
					return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
				} else {
					return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
				}
			} else {
				return (lResult ^ lX8 ^ lY8);
			}
        };

        const _F = (x, y, z) => (x & y) | ((~x) & z);
        const _G = (x, y, z) => (x & z) | (y & (~z));
        const _H = (x, y, z) => x ^ y ^ z;
        const _I = (x, y, z) => y ^ (x | (~z));

        const _FF = (a, b, c, d, x, s, ac) => {
			a = addUnsigned(a, addUnsigned(addUnsigned(_F(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};

        const _GG = (a, b, c, d, x, s, ac) => {
			a = addUnsigned(a, addUnsigned(addUnsigned(_G(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};

        const _HH = (a, b, c, d, x, s, ac) => {
			a = addUnsigned(a, addUnsigned(addUnsigned(_H(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};

        const _II = (a, b, c, d, x, s, ac) => {
			a = addUnsigned(a, addUnsigned(addUnsigned(_I(b, c, d), x), ac));
			return addUnsigned(rotateLeft(a, s), b);
		};

        const convertToWordArray = str => {
			let lWordCount;
			const lMessageLength = str.length;
			const lNumberOfWords_temp1 = lMessageLength + 8;
			const lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
			const lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
			const lWordArray = new Array(lNumberOfWords - 1);
			let lBytePosition = 0;
			let lByteCount = 0;
			while (lByteCount < lMessageLength) {
				lWordCount = (lByteCount - (lByteCount % 4)) / 4;
				lBytePosition = (lByteCount % 4) * 8;
				lWordArray[lWordCount] = (lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition));
				lByteCount++;
			}
			lWordCount = (lByteCount - (lByteCount % 4)) / 4;
			lBytePosition = (lByteCount % 4) * 8;
			lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
			lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
			lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
			return lWordArray;
		};

        const wordToHex = lValue => {
            let wordToHexValue = "";
            let wordToHexValue_temp = "";
            let lByte;
            let lCount;
            for (lCount = 0; lCount <= 3; lCount++) {
				lByte = (lValue >>> (lCount * 8)) & 255;
				wordToHexValue_temp = `0${lByte.toString(16)}`;
				wordToHexValue = wordToHexValue + wordToHexValue_temp.substr(wordToHexValue_temp.length - 2, 2);
			}
            return wordToHexValue;
        };

        let x = [];
        let k;
        let AA;
        let BB;
        let CC;
        let DD;
        let a;
        let b;
        let c;
        let d;
        const S11 = 7;
        const S12 = 12;
        const S13 = 17;
        const S14 = 22;
        const S21 = 5;
        const S22 = 9;
        const S23 = 14;
        const S24 = 20;
        const S31 = 4;
        const S32 = 11;
        const S33 = 16;
        const S34 = 23;
        const S41 = 6;
        const S42 = 10;
        const S43 = 15;
        const S44 = 21;

        str = helpers.utf8_encode( str );
        x = convertToWordArray( str );
        a = 0x67452301;
        b = 0xEFCDAB89;
        c = 0x98BADCFE;
        d = 0x10325476;

        xl = x.length;
        for (k = 0; k < xl; k += 16) {
			AA = a;
			BB = b;
			CC = c;
			DD = d;
			a = _FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
			d = _FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
			c = _FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
			b = _FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
			a = _FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
			d = _FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
			c = _FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
			b = _FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
			a = _FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
			d = _FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
			c = _FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
			b = _FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
			a = _FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
			d = _FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
			c = _FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
			b = _FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
			a = _GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
			d = _GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
			c = _GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
			b = _GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
			a = _GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
			d = _GG(d, a, b, c, x[k + 10], S22, 0x2441453);
			c = _GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
			b = _GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
			a = _GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
			d = _GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
			c = _GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
			b = _GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
			a = _GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
			d = _GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
			c = _GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
			b = _GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
			a = _HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
			d = _HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
			c = _HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
			b = _HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
			a = _HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
			d = _HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
			c = _HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
			b = _HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
			a = _HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
			d = _HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
			c = _HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
			b = _HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
			a = _HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
			d = _HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
			c = _HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
			b = _HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
			a = _II(a, b, c, d, x[k + 0], S41, 0xF4292244);
			d = _II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
			c = _II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
			b = _II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
			a = _II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
			d = _II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
			c = _II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
			b = _II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
			a = _II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
			d = _II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
			c = _II(c, d, a, b, x[k + 6], S43, 0xA3014314);
			b = _II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
			a = _II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
			d = _II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
			c = _II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
			b = _II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
			a = addUnsigned(a, AA);
			b = addUnsigned(b, BB);
			c = addUnsigned(c, CC);
			d = addUnsigned(d, DD);
		}

        const temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);

        return temp.toLowerCase();
    },

	setCookie(c_name, value) {
		Cookie.set(c_name,value, { expires: 1893459600000 });
	},

	getCookie(cname) {
		const name = `${cname}=`;
		const ca = document.cookie.split( ';' );
		for( let i=0; i < ca.length; i++ ) {
			const c = ca[ i ].trim();
			const value = c.substring( name.length, c.length );
			if ( c.indexOf( name )===0 && value != 'null' && value != '0' ) {
				return c.substring( name.length,c.length );
			}
		}
		return '';
	},

	/*
	* Get's the url parameters as javascript object
	* http://stackoverflow.com/questions/979975/how-to-get-the-value-from-url-parameter#answer-1099670
	* @param string qs query string  -  document.location.search
	*/
	getQueryParams(qs) {
        qs = qs.split( '+' ).join( ' ' );

        const params = {};
        let tokens;
        const re = /[?&]?([^=]+)=([^&]*)/g;

        while ( re.exec( qs ) ) {
			tokens = re.exec( qs );
			params[ decodeURIComponent( tokens[ 1 ] ) ] = decodeURIComponent( tokens[ 2 ] );
		}
        return params;
    },


  getAllQueryParams: function(){
    var queryParamsObject = {};
    var SearchString = window.location.search.substring( 1 );
    var VariableArray = SearchString.split( '&' );
    for( var i = 0; i < VariableArray.length; i++ ){
      var KeyValuePair = VariableArray[i].split( '=' );
      //needs to account for any variation of on indexes in []
      if(KeyValuePair.length == 2){
        queryParamsObject[KeyValuePair[0]] = KeyValuePair[1];
      }

    }
    return queryParamsObject;
  },

  /**
   * Call this funciton to scroll the page to the id or class specified
   *
   * @param  string element pass in the full id or class of the element to scroll to the top ( Example: '.class-name' or '#id-name ')
   * @param  number number for pumany pixels from the top to offset. Default is 80;
   *
   * @return animation 	Animates the page
   */
  scrollPageTo: function( element, offsetFromTop, length ) {
    var topOffset = 80;
    var aniLength = 700;
    if ( typeof offsetFromTop != 'undefined' || offsetFromTop !== undefined ) {
      topOffset = offsetFromTop;
    }
    if ( typeof length != 'undefined' || length !== undefined ) {
      aniLength = length;
    }
    $( 'html, body' ).animate( {
      scrollTop: $( element ).offset().top - topOffset,
    }, aniLength, 'swing' );
  },



	/**
	 * Throttle/debounce helper
	 * Modified from http://remysharp.com/2010/07/21/throttling-function-calls/
	 */
	debounce(fn, delay) {
		let timer = null;
		return function() {
            const context = this;
            const args = arguments;

            clearTimeout( timer );

            timer = setTimeout( () => {
				fn.apply( context, args );
			}, delay );
        };
	},
};

if ( typeof String.prototype.trim !== 'function' ) {
	String.prototype.trim = function() {
		return this.replace(/^\s+|\s+$/g, '');
	};
}

export default helpers;

export function setUserParamsLegacy() {
  var d = new Date();
  var dExperation = new Date();
  dExperation = dExperation.setFullYear( 2100 );
  var cookies = {};
  var setTrackerCookie = false;
  var transIdRegex = RegExp("trans_id([[0-9]*]|(%5B[0-9]*%5D))");
  var transIdCommaRegex = RegExp(",");
  var transIdCommaBase64Regex = RegExp("(%2C)");
  var transIdArray = [];

  cookies.ClearChoiceTracker_v2 = [];
  cookies.ClearChoiceTracker_v2.campaigns = [];
  cookies.ClearChoiceTracker_v2.referal = [];
  cookies.ClearChoiceTracker_v2.utm_term = [];
  var tracker = null;
  var SearchString = window.location.search.substring( 1 );
  var VariableArray = SearchString.split( '&' );
  for( var i = 0; i < VariableArray.length; i++ ){
    var KeyValuePair = VariableArray[i].split( '=' );
    //needs to account for any variation of on indexes in []
    if( KeyValuePair[ 0 ] == 'trans_id' || transIdRegex.test(KeyValuePair[ 0 ])){
      if(transIdCommaRegex.test(KeyValuePair[ 1 ]) || transIdCommaBase64Regex.test(KeyValuePair[ 1 ])){
        var trandsIdSplitArray;
        if(transIdCommaBase64Regex.test(KeyValuePair[ 1 ])){
           trandsIdSplitArray = KeyValuePair[ 1 ].split('%2C');
        }else{
           trandsIdSplitArray = KeyValuePair[ 1 ].split(',');
        }
        $.each(trandsIdSplitArray, function(key,value){
           tracker = value;
           if(value != ''){
               transIdArray.push(value);
           }
        });
      } else{
        tracker = KeyValuePair[ 1 ];
        if(KeyValuePair[ 1 ]){
			transIdArray.push(KeyValuePair[ 1 ]);
		}

      }

    }
  }


  var all = document.cookie;

  if( all !== '' ) {
    var list = all.split( '; ' );
    for( var n = 0; n < list.length; n++ ) {
      var cookie = list[ n ];
      var p = cookie.indexOf( '=' );
      var name = cookie.substring( 0, p );
      var value = cookie.substring( p + 1 );
      if( value != 'null' && value != '0' ) {
        if( name == 'ClearChoiceTracker_v2' ) {
          value = helpers.unserialize( helpers.base64_decode( decodeURIComponent( value ) ) );
        } else if ( name == 'userLocation' ) {
          value = helpers.unserialize( decodeURIComponent( value ) );
        } else if ( name == 'GUID' ) {
          value = helpers.unserialize( helpers.base64_decode( decodeURIComponent( value ) ) );
        } else {
          value = decodeURIComponent( value );
        }
        cookies[ name ] = value;
      }
    }
  }


  if( typeof cookies.GUID != 'undefined' && cookies.GUID !== '' ) {
    $( 'input[name="GUID"]' ).val( cookies.GUID );
  }

  var addTracker = true;
  if ( typeof cookies.ClearChoiceTracker_v2 != 'undefined' && typeof cookies.ClearChoiceTracker_v2.campaigns != 'undefined' ) {
    if ( typeof cookies.ClearChoiceTracker_v2.campaigns.Direct == 'undefined' ) {
      setTrackerCookie = true;
      cookies.ClearChoiceTracker_v2.campaigns.Direct = Math.round( d.getTime() / 1000 );
    }

    var tester = new Array(
      null,
      10000000000
    );
    var ClearChoiceTracker = cookies.ClearChoiceTracker_v2.campaigns;
    for ( var index in ClearChoiceTracker ) {
      var timestamp = ClearChoiceTracker[ index ];
      if( index !== 'Direct' && timestamp < tester[ 1 ] ) {
        tester[ 0 ] = index;
        tester[ 1 ] = timestamp;
      }

      if( transIdArray.length > 0 && index == tracker ) {
        $.each(transIdArray,function(index,transId){
            if(index == transId){
              addTracker = false;
            }
        });
      }
    }
  }

  if(transIdArray.length > 0 && addTracker){
      setTrackerCookie = true;
      $.each(transIdArray,function(index,transId){
        cookies.ClearChoiceTracker_v2.campaigns[ transId ] = Math.round( d.getTime() / 1000);
      });
  }

  var referrer = document.referrer;
  if ( referrer !== '' && referrer.match( /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/i ) !== null && referrer.match( /.*clearchoice.*\.com.*/i ) === null ) {
    var addReferrer = true;
    for( i in cookies.ClearChoiceTracker_v2.referal ) {
      if( i == helpers.md5( referrer ) ) {
        addReferrer = false;
      }
    }
    if( addReferrer === true ) {
      setTrackerCookie = true;
      var newReferrer = [];
      newReferrer.uri = referrer;
      newReferrer.time = Math.round( d.getTime() / 1000 );
      cookies.ClearChoiceTracker_v2.referal[ helpers.md5( referrer ) ] = newReferrer;
    }
  }

  //Grab UTM term from url
  var utmTerm = '';
  var utmMedium = '';
  let urlParams;
  try {
    urlParams = helpers.getQueryParams( window.location.search );
    utmTerm = urlParams.utm_term;
    utmMedium = urlParams.utm_medium;
  }
  catch( err ) {
    //Fail Silently. Todo: Send alert to GA
  }

  if ( utmTerm && utmTerm !== '' & utmMedium !== 'listing' && referrer.match( /^(http:\/\/www.|https:\/\/www.|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)/i ) !== null && referrer.match( /.*clearchoice.*\.com.*/i ) === null ) {
    setTrackerCookie = true;
    var newUtmTerm = [];
    newUtmTerm.source = referrer;
    newUtmTerm.keyword = utmTerm;
    newUtmTerm.landing_page = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
    newUtmTerm.time = Math.round( d.getTime() / 1000 );

    cookies.ClearChoiceTracker_v2.utm_term[ helpers.md5( utmTerm ) ] = newUtmTerm;
  }

  if ( setTrackerCookie === true ) {
    helpers.setCookie( 'ClearChoiceTracker_v2', helpers.base64_encode( helpers.serialize( cookies.ClearChoiceTracker_v2 ) ), dExperation );
  }
}
