//var helpers = require( './helper-functions.js' );
import {helpers} from '../global/setUserParamsLegacy';

export let bonelossLP = {
  init: function() {
    bonelossLP.positionTestimonyTimeline();
    bonelossLP.compareMobileSlider();
    bonelossLP.compareListItemHeights();
    bonelossLP.initCustomScrollbar();

    $( window ).on( 'resize', helpers.debounce( function() {
      bonelossLP.positionTestimonyTimeline();
      bonelossLP.compareMobileSlider();
      bonelossLP.compareListItemHeights();
      bonelossLP.initCustomScrollbar();
    }, 200 ) );
  },

  positionTestimonyTimeline: function() {
    $( '.js-compare-wrapper' ).removeAttr( 'style' );
    var $container = $( '.bl-interactive-wrapper' ),
      containerHeight = $container.height(),
      containerWidth = 1000,
      contentWrapperHeight = $( '.js-compare-wrapper' ).height(),
      activePHeight = $( '.bl-time-content.active' ).height();

    // Adjust height of content wrapper
    $( '.js-compare-wrapper' ).css( {
      height: contentWrapperHeight + activePHeight,
    } );

    $( '.js-bl-time' ).each( function() {
      var $this = $( this ),
        dataX = $this.attr( 'data-x' ),
        dataY = $this.attr( 'data-y' );
      if ( $( window ).width() >= 785 ) {
        dataX = $this.attr( 'data-x-desktop' );
      }
      var x = ( ( dataX / containerWidth ) * 100 ) + '%';
      var y = ( ( dataY / containerHeight ) * 100 ) + '%';
      $this.css({ top: y, left: x });
    } );

    // Add active class to content boxes
    $( 'a.js-bl-time' ).on( 'click', function() {
      $( 'a.js-bl-time' ).removeClass( 'active' );
      $( this ).addClass( 'active' );
      $( '.js-compare-wrapper .bl-time-content' ).removeClass( 'active' );
      $( '.js-compare-wrapper ' + $( this ).attr( 'data-content' ) ).addClass( 'active' );

      // Re-adjust height of the content wrapper
      activePHeight = $( $( this ).attr( 'data-content' ) + '.active' ).height();
      $( '.js-compare-wrapper' ).css( {
        height: contentWrapperHeight + activePHeight,
      } );
    } );
  },

  compareMobileSlider: function() {
    if ( $( window ).width() <= 784 ) {
      if ( ! $( '.js-compare-slider' ).hasClass( 'slick-initialized' ) ) {
        $( '.js-compare-slider' ).slick( {
          adaptiveHeight: true,
          draggable: true,
          nextArrow: '<button class="next-compare-btn">Next Comparison<span>&gt;</span></button>',
          prevArrow: false,
          slidesToShow: 1,
        } );
      }
    } else {
      if ( $( '.js-compare-slider' ).hasClass( 'slick-initialized' ) ) {
        $( '.js-compare-slider' ).slick( 'unslick' );
      }
    }
  },

  compareListItemHeights: function() {
    $( '.js-denture-compare, .js-implant-compare' ).removeAttr( 'style' );
    if ( $( window ).width() >= 785 ) {
      var dentureHeight = $( '.js-denture-compare' ).innerHeight(),
        implantHeight = $( '.js-implant-compare' ).innerHeight(),
        headerFinalHeight = Math.max( dentureHeight, implantHeight );
      $( '.js-denture-compare, .js-implant-compare' ).css({ 'height': headerFinalHeight });
    }

    $( '.js-compare-list-one li' ).each( function( index ) {
      var $this = $( this ),
        itemHeight = $this.innerHeight(),
        $matchingItem = $( '.js-compare-list-two li:nth-child( ' + ( index + 1 ) + ' )' ),
        otherHeight = $matchingItem.innerHeight(),
        finalHeight = Math.max( itemHeight, otherHeight );

      if ( $( window ).width() >= 785 ) {
        $this.css({ 'height': finalHeight });
        $matchingItem.css({ 'height': finalHeight });
      } else {
        $this.removeAttr( 'style' );
        $matchingItem.removeAttr( 'style' );
      }
    } );
  },

  initCustomScrollbar: function() {
    if ( $( window ).width() <= 784 ) {
      if( $( '.js-bl-interactive-day' ).hasClass( 'mCustomScrollbar' ) ) {
        $( '.js-bl-interactive-day' ).mCustomScrollbar( 'update' );
      } else {
        $( '.js-bl-interactive-day' ).mCustomScrollbar( {
          axis: 'x',
          scrollInertia: 0,
          alwaysShowScrollbar: false,
          documentTouchScroll: true,
          contentTouchScroll: 10,
          mouseWheel: {
            enable: true,
          },
        } );
      }
    } else {
      if( $( '.js-bl-interactive-day' ).hasClass( 'mCustomScrollbar' ) ) {
        $( '.js-bl-interactive-day' ).mCustomScrollbar( 'destroy' );
      }
    }
  },
};
