export default {
  init() {

    $(".review-switch").click(function(){
      if($(this).hasClass("hidden")){
        $(this).removeClass("hidden");
        //get reviews container and set class for css to show children
        $(this).parent().parent().removeClass("hidden");
        $(this).find(".review-switch-carat").html("v");
      }else{
        $(this).addClass("hidden");
        //get reviews container and set class for css to hide children
        $(this).parent().parent().addClass("hidden");
        $(this).find(".review-switch-carat").html(">");
      }
    });

    $(".doctor-switch").click(function(){
      //set variable for longcontent section of doctor content
      var longContent = $(this).parent().find(".doctor-long-content");
      if(longContent.hasClass("hidden")){
        longContent.removeClass("hidden");
        $(this).find(".doctor-switch-carat").html("v");
      }else{
        longContent.addClass("hidden");
        $(this).find(".doctor-switch-carat").html(">");
      }
    });

    /**
     * Scroll to an (ID) element with some offset to compensate for the mobile menu being in the way
     */


    let buttons = $('ul.single-location--all-buttons li a');

    buttons.click(function(e){

      // size of the menu/logos/etc to offset
      let offset = 240;
      // ID to scroll to (which is defined by the href)
      let target = $(this.getAttribute('href'));

      // scroll to the ID
      if( target.length ) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: target.offset().top - offset,
        }, 1000);
      }

    });

    let anchorbuttons = $('.has-carets a');
    anchorbuttons.click(function(e){
      // size of the menu/logos/etc to offset

      var offset = 180;
      if($(window).width() < 950){
        offset = 108;
        if($(this).attr('href') == '#about-the-center'){
          offset = 121;
        }
      }
      

      // ID to scroll to (which is defined by the href)
      let target = $(this.getAttribute('href'));
      // scroll to the ID
      if( target.length ) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: target.offset().top - offset,
        }, 500);

      }
    });

    //striking distance keyword changes for locations
    if(window.location.pathname.includes("dental-implants-atlanta")){
      $(".bottom-cta-text")[0].innerHTML = "Schedule a free, no obligation consultation online for ClearChoice Dental Implants Atlanta GA today.";
      $("#loction-cta > .container > h2")[0].innerHTML = "Schedule Your Free Consultation At ClearChoice </br> Dental Implants Atlanta GA Today";
    }
    if(window.location.pathname.includes("dental-implants-denver") && window.location.pathname.includes("dental-implants-denver-north") == false){
      $(".bottom-cta-text")[0].innerHTML = "Schedule a free, no obligation consultation online for ClearChoice Dental Implants Colorado today.";
      $("#loction-cta > .container > h2")[0].innerHTML = "Schedule Your Free Consultation At ClearChoice </br>Dental Implants Colorado Today";
    }
    if(window.location.pathname.includes("dental-implants-houston")){
      $(".bottom-cta-text")[0].innerHTML = "Schedule a free, no obligation consultation online for ClearChoice Dental Implants Houston TX today.";
      $("#loction-cta > .container > h2")[0].innerHTML = "Schedule Your Free Consultation At ClearChoice </br>Dental Implants Houston TX Today";
    }
  },
};
