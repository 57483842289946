import moment from 'moment';
import {is_w1, is_w2} from '../global/functions';
import {getMonthName} from './twoMonthCalendar';
import {addConfirmationDetails, hideMessageBox, showPromptToCallMessageBox} from "./formFunctions";
import {getTimeZoneOffsets, getActualTime, printActualTime} from "./time";
import {helpers} from '../global/setUserParamsLegacy';

export function initShowMoreDates(){
	// make sure this only runs once
	var executed = false;
    if (!executed) {
		executed = true;
		$('.moreDates').removeClass('showButton');
		renderHtml();
		addDateInfoAndBasicEvents();
    }

}

function renderHtml(){
	var html = `<hr class="calendar-separator mobile-month-content">
	<div class="col-md-6 mobile-month-content">
		<div id="webFormCalendar4" class="cc-web-form-calendar-month">
            <div class="month-calendar-mobile">
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-day" insp-form-input-id="field-choose-day" type="text" value="" />
                <input style="opacity: 0; display: block; outline: none; border: none; width: 0; height: 0;" class="inspectlet-hidden-tracked js-choose-time" insp-form-input-id="field-choose-time" type="text" value="" />
                <div class="choose-date-month">
                    <div>
                        <div class="calendar-title-nav">
                            <h4>&nbsp;</h4>
                            <a class="btnPrev" title="Earlier"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                        </div>
                        <h3 class="date-selection"><span class="js-day-selection js-change-day"></span><span class="change-day-selection js-change-day">Change</span></h3>
                    </div>
                    <div class="month-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="colSunday">&nbsp;</td>
                                <td class="colMonday">&nbsp;</td>
                                <td class="colTuesday">&nbsp;</td>
                                <td class="colWednesday">&nbsp;</td>
                                <td class="colThursday">&nbsp;</td>
                                <td class="colFriday">&nbsp;</td>
                                <td class="colSaturday">&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
    $('#cal-right-wrap').after(html);
}

function addDateInfoAndBasicEvents(){

	const calendarElementId = "webFormCalendar4";
	const today = new Date();
	// const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	var weekOffset = 0;

	var month = global.calendarInfo.nextMonthToLoad;

	var year = global.calendarInfo.nextYearToLoad;

	var dates = global.calendarInfo.dates;
	var datesArr = global.calendarInfo.datesArr;

	const endDate = global.ccUserInfo.lastAvailableDate;

	// sets dates that will be used for populating templates for calendar left and calendar right
	const calendarDate = new Date( year, ( month - 1 ), 1, 0, 0, 0, 0 );
	const tempDate = new Date( year, ( month - 1), 1, 0, 0, 0, 0 );

	const day = calendarDate.getDay();

	// displays the dates on the calendar template
	var stringDate = `${getMonthName( month )}, ${year}`;

	global.dataLayer.push({ 'currentMonth3': getMonthName( month )});

	//set previous button to only render on left cal and next button to only render on right cal
	const calendarElement = $( `#${calendarElementId} table` );

	const calendarDaySlot = calendarElement.find( 'td' );

	// clear the tables to get it ready for appointment binding
	calendarDaySlot.html( '&nbsp;' ).removeClass( 'currentMonth' ).removeClass( 'currentDay' ).removeClass( 'selected' ).removeClass( 'CalActive' );
	const oH4 = $( `#${calendarElementId} .choose-date-month h4` );


	stringDate = `<span class="blue-month-name-calendar">${getMonthName( month )}</span></br><span class="year-string-calendar">${year}</span>`;

	// add the month and year to the calendar table objects
	oH4.html( stringDate );

	calendarElement.data( 'month', month );
	calendarElement.data( 'year', year );

	// bind event to select a list item
	if ( calendarElement.data( 'boundEvents' ) === null || calendarElement.data( 'boundEvents' ) === undefined ) {
		calendarDaySlot.bind( 'click', function() {
			if( $( this ).hasClass( 'CalActive' ) ) {
				calendarDaySlot.removeClass( 'selected' );
				$( this ).addClass( 'selected' );
			}
		} );
		calendarElement.data( 'boundEvents', true );
	}



	let n = 0;
	// set the date that will iterate in the while loop with each run
	tempDate.setDate( 0 - day );

	//while loop to populate dates in the first or left calendar
	while( n < calendarDaySlot.length ) {
		tempDate.setDate( tempDate.getDate() + 1 );

		calendarDaySlot.eq( n ).html( tempDate.getDate() );

		const yearString = tempDate.getFullYear();
		calendarDaySlot.eq( n ).data( 'year',yearString );

		let monthString = tempDate.getMonth() + 1;
		monthString = `00${monthString}`;
		monthString = monthString.substring( monthString.length - 2, monthString.length );
		calendarDaySlot.eq( n ).data( 'month', monthString );

		let dateString = tempDate.getDate();
		dateString = `00${dateString}`;
		dateString = dateString.substring( dateString.length - 2, dateString.length );

		// Set <td> 'data-day'
		calendarDaySlot.eq( n ).data( 'day', dateString );

		// Adjust dateString
		dateString = `${yearString}-${monthString}-${dateString}`;

		// Reset TD classes
		calendarDaySlot.eq( n ).addClass('currentMonth').removeClass( 'CalFull' );

		// Test Case Specific - slides up the time selector
		calendarDaySlot.eq( n ).removeClass( 'inactiveMonth' );

		if( is_w1() ) {
			$('#webFormCalendar .choose-date-timelist').slideUp();
		}

		if ( tempDate.getMonth() == calendarDate.getMonth() ) {
			calendarDaySlot.eq( n ).addClass( 'currentMonth' );

			if ( datesArr === null ) {
				calendarDaySlot.eq( n ).addClass( 'CalActive' );
			} else if( $.inArray( dateString, datesArr ) >= 0 ) {
				calendarDaySlot.eq( n ).addClass( 'CalActive' );
			}

			//Add "full" class to full days
			const dateStr = dateString.replace( /-/g, '/');

			if ( typeof dates[ dateStr ] != 'undefined' ) {

				// Checking for same-day scheduling
				if ( tempDate.getDate() === today.getDate() ) {
					let todayFull = true;

					// if ( global.ccUserInfo.marketScore === 'GYIM' || global.ccUserInfo.leadSegment == 4 || global.ccUserInfo.leadSegment == 5) {
						const apps = dates[ dateStr ].appointmentSlots;
						// build out calendar appts for time
						for ( let i = apps.length - 1; i >= 0; i-- ) {
							// Building moment.js string from appt date/time
							const time = apps[i].start;

							const wholeTime = time.replace( ' ', '.0' );
							const thisDay = apps[i].date;
							const finalDate = `${thisDay} ${wholeTime}`;

							// Appointment is more than 2 hours away & has a block (it's available)
							if ( ( moment( finalDate ) - moment( today ) > 7200000 ) && apps[i].blocks.length ) {
								// Set flag to false ( so we don't "block" off today );
								todayFull = false;
							} else {
								// Set block to "empty/scheduled"
								apps[i].blocks = [];
							}
						}
					// }

					// If todayFull is still true ( no appts match "more than 2 hours in the future" AND "still open in SF" )
					if ( todayFull ) {
						calendarDaySlot.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
					}
				}
				// End Same-Day Schedule adjustments

				// If day is full:
				if ( dates[ dateStr ].full ) {
					calendarDaySlot.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
				}

				if ( dates[ dateStr ].promptToCall != undefined &&  dates[ dateStr ].promptToCall == true) {
					calendarDaySlot.eq( n ).addClass('promptToCall');
				}
			}
		} else {
			calendarDaySlot.eq( n ).addClass( 'inactiveMonth' );
		}
		// add current day display to first calendar
		if( tempDate.getMonth() == today.getMonth() && tempDate.getDate() == today.getDate() ) {
			calendarDaySlot.eq( n ).addClass( 'currentDay' );
		}


		if( tempDate.getMonth() == endDate.getMonth() && tempDate.getDate() == endDate.getDate() ) {
			calendarDaySlot.eq( n ).addClass( 'endDay' );
		}

		n++;
	}

	// Hide the last <tr> if all of the dates in the row are ".inactiveMonth"
	calendarElement.find( 'tr' ).each( function() {
		if ( $( this ).find( 'td.inactiveMonth' ).length === 7 ) {
			$( this ).hide();
		} else {
			$( this ).show();
		}
	} );
}

export function removeThirdCalendar(){
	$("#webFormCalendar4 *").unbind();
	$(".mobile-month-content").remove();
}
