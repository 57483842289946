import {retrieveUnfilteredAppiontmentsNearest} from './retrieveAppointments'
import {showMessageBox} from '../form/formFunctions';

export function setOathToken() {
	var $messageBox = $("#webform-component-messages");
	var callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

	var ajaxData = {};
	ajaxData.action = 'cc_salesforce_set_salesforce_oauth';
	// ajaxData.requestId = getOauthRequestId();
	$.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: ajaxData,
      success: function(msg) {
		if(msg == "oath token not set"){
			$messageBox.find( '.message-text' ).html('<div class="alert alert-danger" role="alert">There was an issue starting the scheduling process. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
			showMessageBox();
			global.dataLayer.push({ 'event': 'Oauth Token Error' });
			$(".gform_next_button").attr('disabled',true);
		} else{
			global.securityToken = msg;
			//if($('.pre-form').length == 0){
				//retrieveUnfilteredAppiontmentsNearest();
			//}
			global.dataLayer.push({ 'event': 'Oauth Token Success' });
		}

      },
      error: function() {
      },
	});
}

// //this is to get a unique id for each response for the sake of testing and comparing
// function getOauthRequestId(){
// 	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
// 		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
// 		return v.toString(16);
// 	});
// }
