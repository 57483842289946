import queryString from 'query-string';

if( $('.smart-call-back').length > 0 ) {
  const parsed = queryString.parse(location.search);
  var formattedNumber;
  var unformatedNumber;
  if(parsed.tel != undefined){
    formattedNumber = parsed.tel;
    unformatedNumber;

    formattedNumber = formattedNumber.replace('(','');
    formattedNumber = formattedNumber.replace(') ','-');

    unformatedNumber = formattedNumber.replace('-','');
  }else{
    formattedNumber = "888-651-9950";
    unformatedNumber = formattedNumber.replace('-','');
  }

  $("#nav-sticky-phone-number").hide();
  $('.mobile-number a').hide();
  $("#nav-sticky-cta a").text("CALL YOUR LOCAL CENTER TODAY");
  $("#nav-sticky-cta a").attr('href','tel:'+unformatedNumber);
  $(".schedule-button-container .schedule-button").addClass("smart-call-back-button");
  $(".smart-call-back-button").text("CALL YOUR LOCAL CENTER TODAY");
  $(".smart-call-back-button").attr('href','tel:'+unformatedNumber);
  $(".smart-call-back-button").attr("style","margin: auto;");

  $('.smart-callback-tel').html(formattedNumber);
  $('.smart-callback-tel').attr('href','tel:'+unformatedNumber);
  // add dynamic link above the footer of the page.
  $(".dynamic-phone").after(`<b><span> Call <a href="tel:${unformatedNumber}" style="text-decoration:none;">${formattedNumber}</a> for more information.</span></b>`);
}
