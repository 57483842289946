

/**
 * Hide "waiting" icon
 *
 * @return null
 */
export function hideWaitingIcon()	{
	var $loadingIcon = $( '#waiting-icon' );
	$loadingIcon.hide();
}

/**
 * Show "waiting" icon
 *
 * @return null
 */
export function showWaitingIcon( message ) {
	var $loadingIcon = $( '#waiting-icon' );
	$loadingIcon.find( '.message' ).html( message );
	$loadingIcon.show();
}