import Cookie from 'js-cookie';
import queryString from 'query-string';

export function setUserParams() {
  let phoneNumber = '888-651-9950';
  const parsed = queryString.parse(location.search);
  let trans_id = parsed.trans_id;

   // parse second trans_id from multiple in URL string
   if ( typeof trans_id !== 'undefined' && trans_id.includes(",") ) {
    let res = trans_id.split(",");
    trans_id = res.pop();
  }

  // Set phone number based on last trans_id they had
  if (trans_id !== null && campaigns[trans_id] !== null && campaigns[trans_id] !== '' && typeof campaigns[trans_id] != 'undefined') {
    if (campaigns[trans_id].parentPhone !== '' && campaigns[trans_id].campaignPhone !== '') {
      // 888 numbers should always take precedence
      if (campaigns[trans_id].parentPhone > campaigns[trans_id].campaignPhone) {
        phoneNumber = campaigns[trans_id].parentPhone;
      } else {
        phoneNumber = campaigns[trans_id].campaignPhone;
      }
    } else if (campaigns[trans_id].parentPhone !== '' && campaigns[trans_id].campaignPhone === '') {
      phoneNumber = campaigns[trans_id].parentPhone;
    } else if (campaigns[trans_id].campaignPhone !== '' && campaigns[trans_id].parentPhone === '') {
      phoneNumber = campaigns[trans_id].campaignPhone;
    }

    if (phoneNumber.length && phoneNumber.length == 10) {
      phoneNumber = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6,4);
    }

    Cookie.set('campaign_phone_number', phoneNumber);
    updateNumber(phoneNumber);
  } else if (Cookie.get('campaign_phone_number')) {
    phoneNumber = Cookie.get('campaign_phone_number');
    updateNumber(phoneNumber);
  }

  if (!Cookie.get('campaign_phone_number')) {
    updateNumber(phoneNumber);
  }

  // Set the trans_id cookie
  if (trans_id !== undefined) {
    Cookie.set('trans_id', trans_id);
  }
}

export function updateNumber(phoneNumber) {
  if (phoneNumber.length && phoneNumber.length == 10) {
    phoneNumber = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6,4);
  }
  $('.banner .menu-phone').html('<a href="tel:'+phoneNumber+'"><i class="fa fa-phone" aria-hidden="true"></i> '+phoneNumber+'</a>');
  $('.banner-bottom .menu-phone').html('<a href="tel:'+phoneNumber+'"><i class="fa fa-phone" aria-hidden="true"></i> '+phoneNumber+'</a>');
  $('.trans_id').each(function() {
    $(this).html(phoneNumber);
  });
}
