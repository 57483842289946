
/**
 * Generic/shareable functions for the OS and Partial forms
 */

export function is_w1() {
  return $('body.theme-w1').length > 0
}


export function is_w2() {
  return $('body.theme-w2').length > 0
}
