import {Site} from './w1-only';

export let ccMenu = {
    init: function() {
        this.navigationMenu();
        this.wrapFirstWord();

        // we have to init this in w1-only.js because of viewpoints or whatever
        //this.addStickyMenu();
    },

    getMenu: function() {
        return $('#nav-sticky-items');
    },

    getMobileMenu: function() {
        return $('#navigation > ul');
    },

    navigationMenu: function() {
        let tap = false;
        let $menu = this.getMobileMenu();


        $(document).on( 'touchstart', function( e ){
            tap = true;
            setTimeout( function(){
                tap = false;
            }, 1000);
        } );

        $( '.menu-toggle-button' ).click( function() {
            //window.scrollTo(0, 0);
            $( this ).toggleClass( 'open' );
            $( '#navigation' ).slideToggle();
            $menu.find( '> li' ).removeClass( 'faux-hovering' );

            if( $( this ).hasClass( 'open' ) ){
                if( tap ){
                    ccMenu.limitMenuWindow();
                }
            }else{
                $( 'body' ).attr( 'style', '' );
            }
        } );

        $menu.find('> li > a' ).bind( 'touchend', function( e ) {
            // THIS ISN'T NEEDED IF THE CIV-HEADER LOSES
            var $parent = $( this ).parent();

            if ( $( '.menu-toggle-button' ).is( ':visible' ) ) {

                if ( $parent.hasClass( 'faux-hovering' ) ) {
                    $( '#navigation ul > li' ).removeClass( 'faux-hovering' );
                    $( 'body' ).attr( 'style', '' );
                } else {
                    $( '#navigation ul > li' ).removeClass( 'faux-hovering' );
                    $parent.addClass( 'faux-hovering' );
                }

                ccMenu.limitMenuWindow();

                e.stopPropagation();
                e.preventDefault();
                return false;
            } else {
                // THIS ISN'T NEEDED IF THE CIV-HEADER WINS
                var $this = $( this ).parent();
                var shouldStopLink = false;

                if( $this.find( '> .sub-menu' ).length > 0 && !$this.hasClass( 'faux-hovering' ) ){
                    shouldStopLink = true;
                }

                $( '#navigation ul > li' ).removeClass( 'faux-hovering' );

                $this.addClass( 'faux-hovering' );

                if( shouldStopLink ){
                    return false;
                }
            }
        } );

        $menu.find('> li > a' ).bind( 'mouseup mouseenter', function( e ) {
            if ( !tap ) {
                var $this = $( this ).parent();
                var shouldStopLink = false;
                if ( $this.find( '> div' ).length > 0 && ! $this.hasClass( 'faux-hovering' ) ) {
                    shouldStopLink = true;
                }

                $( '#navigation ul > li' ).removeClass( 'faux-hovering' );

                $this.addClass( 'faux-hovering' );

                if ( shouldStopLink ) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }
            }else{
                return false;
            }
        } );

        $menu.find('> li > a' ).bind( 'click', function( e ) {
          e.preventDefault();
        } );

        $menu.find('> li' ).bind( 'mouseleave', function() {
            if ( !tap ) {
                var $this = $( this );

                $this.removeClass( 'faux-hovering' );
            }
        } );
    },

    limitMenuWindow: function() {
        var windowHeight = window.innerHeight;
        var menuHeight = $( '.menu-header-menu-container' ).height();
        menuHeight += $( '.schedule-button-container-mobile' ).height();
        menuHeight += $( '.menu-toggle-button' ).height();

        var newHeight = windowHeight;
        if( menuHeight > windowHeight ){
            newHeight = menuHeight;
        }

        $( 'body' ).css( {
            'height' : newHeight,
            'overflow' : 'hidden',
        } );
    },


    // main menu: wrap the first word in its own div
    wrapFirstWord: function() {
        this.getMenu().find('> li > a').each(function(e){
            let text = $(this).text().trim().split(" ");
            let first = text.shift();
            let html = (text.length > 0 ? '<div class="nav-sticky-title">' + first + '</div> ' : first);
            html += text.join(" ");

            $(this).html(html);
        });
    },









    /*****************************************************************************************
     * Sticky menu
     *****************************************************************************************/

    addStickyMenu: function() {
      //Sticky Menu Bar
      var $header = $( '#header' );
      var headerHeight = $header.height();
      var headerDown = false;

      var currentPage = window.location.pathname;

      var $body = $("body");

      if(currentPage != '/' && ! $body.hasClass( 'template-consultation-form' ) ) {
        var $content = $( '#container' );
        var $window = $( window );

        $window.scroll( function() {

          if ( Site.viewportWidth > Site.breakPoints.tabletLandscape.maxWidth ) {
            if ( $( this ).scrollTop() >= headerHeight && !headerDown ) {
              $header.hide().addClass( 'skinny' );
              $content.addClass( 'headerDown' );
              headerDown = true;
              $header.slideDown( 'fast' );
            } else if ( $( this ).scrollTop() < headerHeight && headerDown ) {
              $header.removeClass( 'skinny' );
              $content.removeClass( 'headerDown' );
              headerDown = false;
            }
          }
        } );
      }
    },


    hamburgerHelper: function() {
      var navIsSticky = false;

      function throttle( func, wait, options ) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if ( ! options ) options = {};
        var later = function() {
          previous = options.leading === false ? 0 : $.now();
          timeout = null;
          result = func.apply(context, args);
          if ( ! timeout ) context = args = null;
        };
        return function() {
          var now = $.now();
          if ( ! previous && options.leading === false ) previous = now;
          var remaining = wait - ( now - previous );
          context = this;
          args = arguments;
          if ( remaining <= 0 || remaining > wait ) {
            if ( timeout ) {
              clearTimeout( timeout );
              timeout = null;
            }
            previous = now;
            result = func.apply( context, args );
            if ( ! timeout ) context = args = null;
          } else if ( ! timeout && options.trailing !== false ) {
            timeout = setTimeout( later, remaining );
          }
          return result;
        };
      }

      function setMenuStyle() {

        if ( ! Site.largeScreen ) {

          //If they've passed the original header height
          if ( $( window ).scrollTop() > 125 && ! navIsSticky ) {
            // if not on OS template page
            if( $( 'body.template-consultation-form' ).length === 0 ) {

              //Make the header sticky
              $( '#navloc > div.header-cta > div.schedule-button-container' ).addClass( 'sticky' ).css( { position: 'fixed', top: '-38px' } ).animate( { top: '10px' }, 400 );
              $( '#header > div > div.menu-toggle-button').addClass( 'sticky' ).css( { position: 'fixed', top: '-38px' } ).animate( { top: '10px' }, 400 );
              $( '#hamburger-background-div' ).addClass( 'sticky' ).css( { position: 'fixed', top: '-58px' } ).animate( { top: '-1px' }, 400 );
              //$( '#header div.callToday.mobile-number' ).addClass( 'sticky' ).css( { position: 'fixed' } ).animate( { top: '10px' } );
              $( '#navigation' ).addClass( 'sticky' );
              navIsSticky = true;
            }

          } else if ( $( window ).scrollTop() < 125 ) {
            //Reset the header to its initial state
            $( '#navloc > div.header-cta > div.schedule-button-container' ).removeClass( 'sticky' ).css( { position: 'absolute', top: '54px' } );
            $( '#header > div > div.menu-toggle-button' ).removeClass( 'sticky' ).css( { position: 'absolute', top: '54px' } );
            $( '#hamburger-background-div' ).removeClass( 'sticky' ).css( { position: 'absolute', top: '54px' } );
            $( '#header div.callToday.mobile-number' ).removeClass( 'sticky' ).css( { position: 'static', top: '-27px' } );
            $( '#navigation' ).removeClass( 'sticky' );
            navIsSticky = false;
          }
        } else if ( Site.largeScreen ) {
          $( '#navloc > div.header-cta > div.schedule-button-container' ).removeClass( 'sticky' ).css( { position: 'relative', top: '0' } );
          $( '#header > div > div.menu-toggle-button' ).removeClass( 'sticky' ).css( { position: 'relative', top: '0' } );
          $( '#hamburger-background-div' ).removeClass( 'sticky' ).css( { position: 'relative', top: '0' } );
          $( '#header div.callToday.mobile-number' ).removeClass( 'sticky' ).css( { position: 'static', top: '-27px' } );
          $( '#navigation' ).removeClass( 'sticky' );
        }
      }

      setMenuStyle();

      //Every 100ms while the user is scrolling
      //(use the throttle.js to activate code)
      $( window ).on( 'touchmove, resize, scroll', throttle( function() {
        //Make sure the menu is properly configured
        setMenuStyle();
      }, 100 ) );

      //After the user has made a touch event
      $( window ).on( 'touchend, resize', function() {
        //Wait 200ms
        setTimeout(function() {
          //Make sure the menu is properly configured
          setMenuStyle();
        }, 200 );
      } );

      //Whenever the user clicks on the opaque layer of the navigation (below the menu buttons)
      $( '.wrapper' ).on( 'click', '#navigation', function( e ) {
        if( e.target.id === 'navigation' ) {
          //Trigger a click on the menu close
          $( '.icon-container.js-open' ).trigger( 'click' );
        }
      } );
    },







};

