export default {
  init() {
    /**
     * change the text to spanish on the partial form
     */
    let partial_form = $('form.schedule-consultation-pre-form');

    if (partial_form.length) {

      // h2 title
      partial_form.find('.form-page-header h2').html('Haga una <br class="w1-only">Consulta Gratis');

      // input fields
      // key is the css class li.field-xxxxxxx
      let fields = {
        'firstName': 'Nombre',
        'lastName': 'Apellido',
        'phone': 'Teléfono',
        'address': 'Dirección',
        'zipcode': 'Código Postal',
        'city': 'Ciudad',
        'state': 'Estado',
      };

      for ( let f in fields ) {
        partial_form.each( function(){
          $(this).find('li.field-' + f + ' label').contents().get(0).nodeValue = fields[f];
        });
      }
    }


  },

};
